import React from 'react';
import { Link } from 'react-router-dom';

const Sidebar = () => {
  return (
    <div className="w-64 h-screen bg-gray-800 text-white flex flex-col">
      <h2 className="text-2xl p-4">Admin Panel</h2>
      <ul className="flex flex-col">
        <li className="p-4 hover:bg-gray-700">
          <Link to="/">Top Destinations</Link>
        </li>
        <li className="p-4 hover:bg-gray-700">
          <Link to="/index-two">Tour Packages</Link>
        </li>
        <li className="p-4 hover:bg-gray-700">
          <Link to="/customerfeedback">Customer Feedback</Link>
        </li>
        <li className="p-4 hover:bg-gray-700">
          <Link to="/blogs">Blogs</Link>
        </li>
        <li className="p-4 hover:bg-gray-700">
          <Link to="/managedestinations">Manage Destinations</Link>
        </li>
        <li className="p-4 hover:bg-gray-700">
          <Link to="/manageplaces">Manage Places</Link>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
