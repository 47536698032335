import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Sidebar from '../components/Sidebar';

const ManagePlaces = () => {
  const [places, setPlaces] = useState([]);
  const [destinationId, setDestinationId] = useState('');
  const [name, setName] = useState('');
  const [editingId, setEditingId] = useState(null);

  useEffect(() => {
    fetchPlaces();
  }, []);

  const fetchPlaces = async () => {
    try {
      const response = await axios.get('http://localhost:5000/api/places');
      setPlaces(response.data);
    } catch (error) {
      console.error('Error fetching places:', error);
    }
  };

  const handleAddOrUpdate = async (e) => {
    e.preventDefault();
    try {
      if (editingId) {
        await axios.put(`http://localhost:5000/api/places/${editingId}`, { name, destinationId });
      } else {
        await axios.post('http://localhost:5000/api/places', { name, destinationId });
      }
      setName('');
      setDestinationId('');
      setEditingId(null);
      fetchPlaces();
    } catch (error) {
      console.error('Error saving place:', error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`http://localhost:5000/api/places/${id}`);
      fetchPlaces();
    } catch (error) {
      console.error('Error deleting place:', error);
    }
  };

  const handleEdit = (place) => {
    setName(place.name);
    setDestinationId(place.destinationId);
    setEditingId(place._id);
  };

  return (
    <div className="flex">
      <Sidebar />
      <div className="flex-1 p-6">
        <h1 className="text-3xl mb-4">Manage Places</h1>
        <form onSubmit={handleAddOrUpdate} className="mb-4">
          <input
            type="text"
            placeholder="Place Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="border p-2 mr-2"
            required
          />
          <input
            type="text"
            placeholder="Destination ID"
            value={destinationId}
            onChange={(e) => setDestinationId(e.target.value)}
            className="border p-2 mr-2"
            required
          />
          <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded">
            {editingId ? 'Update' : 'Add'}
          </button>
        </form>

        <div>
          {places.map((place) => (
            <div key={place._id} className="p-4 border rounded shadow mb-2 flex justify-between">
              <span>{place.name} (Destination ID: {place.destinationId})</span>
              <div>
                <button className="bg-green-500 text-white px-3 py-1 rounded mr-2" onClick={() => handleEdit(place)}>
                  Edit
                </button>
                <button className="bg-red-500 text-white px-3 py-1 rounded" onClick={() => handleDelete(place._id)}>
                  Delete
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ManagePlaces;
