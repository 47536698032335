import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Sidebar from '../components/Sidebar';

const ManageDestinations = () => {
  const [destinations, setDestinations] = useState([]);
  const [name, setName] = useState('');
  const [editingId, setEditingId] = useState(null);

  useEffect(() => {
    fetchDestinations();
  }, []);

  const fetchDestinations = async () => {
    try {
      const response = await axios.get('http://localhost:5000/api/destinations');
      setDestinations(response.data);
    } catch (error) {
      console.error('Error fetching destinations:', error);
    }
  };

  const handleAddOrUpdate = async (e) => {
    e.preventDefault();
    try {
      if (editingId) {
        await axios.put(`http://localhost:5000/api/destinations/${editingId}`, { name });
      } else {
        axios.post('http://localhost:5000/api/destinations/add', { name: destinations });

      }
      setName('');
      setEditingId(null);
      fetchDestinations();
    } catch (error) {
      console.error('Error saving destination:', error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`http://localhost:5000/api/destinations/${id}`);
      fetchDestinations();
    } catch (error) {
      console.error('Error deleting destination:', error);
    }
  };

  const handleEdit = (destination) => {
    setName(destination.name);
    setEditingId(destination._id);
  };

  return (
    <div className="flex">
      <Sidebar />
      <div className="flex-1 p-6">
        <h1 className="text-3xl mb-4">Manage Destinations</h1>
        <form onSubmit={handleAddOrUpdate} className="mb-4">
          <input
            type="text"
            placeholder="Destination Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="border p-2 mr-2"
            required
          />
          <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded">
            {editingId ? 'Update' : 'Add'}
          </button>
        </form>

        <div>
          {destinations.map((destination) => (
            <div key={destination._id} className="p-4 border rounded shadow mb-2 flex justify-between">
              <span>{destination.name}</span>
              <div>
                <button className="bg-green-500 text-white px-3 py-1 rounded mr-2" onClick={() => handleEdit(destination)}>
                  Edit
                </button>
                <button className="bg-red-500 text-white px-3 py-1 rounded" onClick={() => handleDelete(destination._id)}>
                  Delete
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ManageDestinations;
