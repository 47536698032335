import React from "react";
import { Link } from "react-router-dom";

import Navbar from "../../../components/navbar";
import Filter from "../../../components/filter";
import Footer from "../../../components/footer";
import Switcher from "../../../components/switcher";

import { packages } from "../../../data/data";

import {FiMapPin, FiChevronLeft, FiChevronRight} from '../../../assets/icons/vander'

export default function ListLeftSidebar(){
    return(
        <>
        <Navbar navclass="defaultscroll is-sticky" navlight={true} manuclass="justify-end nav-light"/>

        <section className="relative table w-full items-center py-36 bg-[url('../../assets/images/bg/cta.jpg')] bg-top bg-no-repeat bg-cover">
            <div className="absolute inset-0 bg-gradient-to-b from-slate-900/60 via-slate-900/80 to-slate-900"></div>
            <div className="container relative">
                <div className="grid grid-cols-1 pb-8 text-center mt-10">
                    <h3 className="text-4xl leading-normal tracking-wider font-semibold text-white">Tour Packages</h3>
                </div>
            </div>
            
            <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                <ul className="tracking-[0.5px] mb-0 inline-block">
                    <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/">Flyplaces</Link></li>
                    <li className="inline-block text-base text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180"><i className="mdi mdi-chevron-right"></i></li>
                    <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white" aria-current="page">Tour</li>
                </ul>
            </div>
        </section>

        <section className="relative md:py-24 py-16">
            <div className="container relative">
                <div className="grid md:grid-cols-12 grid-cols-1 gap-6">
                    <Filter/>

                    <div className="lg:col-span-8 md:col-span-8">
                        <div className="grid grid-cols-1 gap-6">                    
                            {packages.map((item,index)=>{
                                return(
                                    <div className="group rounded-md shadow dark:shadow-gray-700" key={index}>
                                        <div className="md:flex md:items-center">
                                            <div className="relative overflow-hidden md:shrink-0 md:rounded-md rounded-t-md shadow dark:shadow-gray-700 md:m-3 mx-3 mt-3">
                                                <img src={item.image} className="h-full w-full object-cover md:w-48 md:h-56 scale-125 group-hover:scale-100 duration-500" alt=""/>
                                                {item.tagText && (
                                                    <div className="absolute top-0 start-0 p-4">
                                                        <span className="bg-red-500 text-white text-[12px] px-2.5 py-1 font-medium rounded-md h-5">{item.tagText}</span>
                                                    </div>
                                                )}
                    
                                                <div className="absolute top-0 end-0 p-4">
                                                    <Link to="#" className="size-8 inline-flex justify-center items-center bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 rounded-full text-slate-100 dark:text-slate-700 focus:text-red-500 dark:focus:text-red-500 hover:text-red-500 dark:hover:text-red-500"><i className="mdi mdi-heart text-[20px] align-middle"></i></Link>
                                                </div>
                                            </div>
                                            
                                            <div className="p-4 w-full">
                                                <p className="flex items-center text-slate-400 font-medium mb-2"><FiMapPin className="text-red-500 size-4 me-1"></FiMapPin> {item.place}</p>
                                                <Link to={`/tour-detail-one/${item.id}`} className="text-lg font-medium hover:text-red-500 duration-500 ease-in-out">{item.title}</Link>

                                                <div className="flex items-center mt-2">
                                                    <span className="text-slate-400">Rating:</span>
                                                    <ul className="text-lg font-medium text-amber-400 list-none ms-2 space-x-1">
                                                        <li className="inline"><i className="mdi mdi-star align-middle"></i></li>
                                                        <li className="inline"><i className="mdi mdi-star align-middle"></i></li>
                                                        <li className="inline"><i className="mdi mdi-star align-middle"></i></li>
                                                        <li className="inline"><i className="mdi mdi-star align-middle"></i></li>
                                                        <li className="inline"><i className="mdi mdi-star align-middle"></i></li>
                                                        <li className="inline text-black dark:text-white text-sm">5.0(30)</li>
                                                    </ul>
                                                </div>
                                                
                                                <div className="mt-4 pt-4 flex justify-between items-center border-t border-slate-100 dark:border-gray-800">
                                                    <h5 className="text-lg font-medium text-red-500">$ 58 / Day</h5>

                                                    <Link to="" className="text-slate-400 hover:text-red-500">Explore Now <i className="mdi mdi-arrow-right"></i></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })} 
                        </div>
        
                        <div className="grid md:grid-cols-12 grid-cols-1 mt-6">
                            <div className="md:col-span-12 text-center">
                                <nav aria-label="Page navigation example">
                                    <ul className="inline-flex items-center -space-x-px">
                                        <li>
                                            <Link to="#" className="size-[40px] inline-flex justify-center items-center text-slate-400 bg-white dark:bg-slate-900 rounded-s-3xl hover:text-white border border-gray-100 dark:border-gray-800 hover:border-red-500 dark:hover:border-red-500 hover:bg-red-500 dark:hover:bg-red-500">
                                                <FiChevronLeft className="size-5 rtl:rotate-180 rtl:-mt-1"></FiChevronLeft>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="#" className="size-[40px] inline-flex justify-center items-center text-slate-400 hover:text-white bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-800 hover:border-red-500 dark:hover:border-red-500 hover:bg-red-500 dark:hover:bg-red-500">1</Link>
                                        </li>
                                        <li>
                                            <Link to="#" className="size-[40px] inline-flex justify-center items-center text-slate-400 hover:text-white bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-800 hover:border-red-500 dark:hover:border-red-500 hover:bg-red-500 dark:hover:bg-red-500">2</Link>
                                        </li>
                                        <li>
                                            <Link to="#" aria-current="page" className="z-10 size-[40px] inline-flex justify-center items-center text-white bg-red-500 border border-red-500">3</Link>
                                        </li>
                                        <li>
                                            <Link to="#" className="size-[40px] inline-flex justify-center items-center text-slate-400 hover:text-white bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-800 hover:border-red-500 dark:hover:border-red-500 hover:bg-red-500 dark:hover:bg-red-500">4</Link>
                                        </li>
                                        <li>
                                            <Link to="#" className="size-[40px] inline-flex justify-center items-center text-slate-400 hover:text-white bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-800 hover:border-red-500 dark:hover:border-red-500 hover:bg-red-500 dark:hover:bg-red-500">5</Link>
                                        </li>
                                        <li>
                                            <Link to="#" className="size-[40px] inline-flex justify-center items-center text-slate-400 bg-white dark:bg-slate-900 rounded-e-3xl hover:text-white border border-gray-100 dark:border-gray-800 hover:border-red-500 dark:hover:border-red-500 hover:bg-red-500 dark:hover:bg-red-500">
                                                <FiChevronRight className="size-5 rtl:rotate-180 rtl:-mt-1"></FiChevronRight>
                                            </Link>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer/>
        <Switcher/>
        </>
    )
}